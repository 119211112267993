import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import theme from "src/components/themes/DefaultTheme";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import styled, { css } from 'src/lib/styles/css';
import { ProgramMovie } from "src/lib/types/filminfo/ProgramMovie";
import { format } from "date-fns";
import Image from "src/components/images/Image";
import Poster from "src/components/posterrow/Poster";
import MoviePosterImage from "src/components/program/MoviePosterImage";
import { ProgramShow } from "src/lib/types/filminfo/ProgramShow";
import Kinoklubb from "src/components/kinoklubb/Kinoklubb";
import Show from "src/components/film/program/Show";
import Button from "src/components/button/Button";
import { useState } from "react";

//#region [Props]
type ProgrammedMovieProps = {
	movie: ProgramMovie;
};
//#endregion

//#region [Component]
export default function ProgrammedMovie({ movie }: ProgrammedMovieProps) {
	const selectedDateContext = useProgramDatesContext();
	const [isExpanded, setIsExpanded] = useState(false);

	const selDate = selectedDateContext?.selectedDate ? `#${format(selectedDateContext.selectedDate, 'dd.MM.yyyy')}` : '';
	let genres = movie.genres;
	if (genres?.includes("Barnefilm") && genres?.includes("Familiefilm")) {
		genres = genres.filter(g => g !== "Barnefilm");
	}
	return <SMovieContainer>
		<SMovie>
			<SPosterContainerLink to={`/film/${movie.mainVersionId}${selDate}`}>
				<SPosterImage movie={movie} missingPosterOffCentre />
			</SPosterContainerLink>
			<SInfo>
				<STitleLink to={`/film/${movie.mainVersionId}${selDate}`}><SMovieTitle>{movie.title}</SMovieTitle></STitleLink>
				<SFacts>
					<SNoWrap>{movie.rating}</SNoWrap>
					{!!movie.lengthInMinutes && <SNoWrap>
						{Math.floor(movie.lengthInMinutes / 60) > 0 ? `${Math.floor(movie.lengthInMinutes / 60)}t ` : null}
						{`${movie.lengthInMinutes - 60 * Math.floor(movie.lengthInMinutes / 60)}m`}
					</SNoWrap>}
					{(genres?.length ?? 0) > 0 && <SGenre>{genres!.join(" / ")}</SGenre>}
				</SFacts>
				{movie.isKinoklubb && areAnyShowsKinoklubb(movie.filteredShows) && <SKinoklubb />}
				<SProgram>
					{movie.filteredShows?.slice(0, 3)?.map((show, index) => <Show
						key={show?.ticketSaleUrl ?? index}
						show={show!}
						isKinoklubbMovie={movie.isKinoklubb} />)}
					{(movie.filteredShows?.length ?? 0) > 3 && <SExpander $isExpanded={isExpanded}>
						{movie.filteredShows?.slice(3)?.map((show, index) => <Show
							key={show?.ticketSaleUrl ?? index}
							show={show!}
							isKinoklubbMovie={movie.isKinoklubb} />)}
					</SExpander>}
				</SProgram>
				<SLinks>
					<SReadMoreLink to={`/film/${movie.mainVersionId}${selDate}`}>Les mer</SReadMoreLink>
					{(movie.filteredShows?.length ?? 0) > 3 && <Button text={isExpanded ? "Færre forestillinger" : "Flere forestillinger"} onClick={() => setIsExpanded(!isExpanded)} inverse />}
				</SLinks>
			</SInfo>
		</SMovie>
	</SMovieContainer>;
}
//#endregion

//#region [Other] Helpers
function areAnyShowsKinoklubb(shows: ProgramShow[] | undefined | null) {
	return shows?.some(show => show.isKinoklubb) ?? false;
}
//#endregion

//#region [Styles]
const SMovieContainer = styled.article`
	overflow: hidden;
	border-radius: 8px;
	// this is to hide the ugly interaction of white posters
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin: 0 10px 20px 10px;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		margin: 0;
	}
`;

const SMovie = styled.div`
	padding-top: 0;


	box-sizing: border-box;
	display: flex;
	position: relative;
	transition: padding-top 0.5s;

	align-items: flex-start;
`;

const SPosterContainerLink = styled(FilmwebLink)`
	position: absolute;
	top: 0;
	z-index: 1;
	width: 100%;
	text-decoration: none;
`;

const SPosterImage = styled(MoviePosterImage)`
	&:hover {
		cursor: pointer;
	}

    @supports (aspect-ratio: ${1 / theme.posterAspectRatio}) {
		height: 100%;
		aspect-ratio: ${1 / theme.posterAspectRatio}
	}
`;

const SInfo = styled.div`
	z-index: 2;
	//align-self: flex-end;
	width: 100%;
	background: var(--buttoncolorAlpha);
    align-self: flex-start;
	padding-bottom: 10px;
	//backdrop-filter: blur(7px); // NOTE: This seems to be performance heavy
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
    margin-top: 125%;
`;

const STitleLink = styled(FilmwebLink)`
	text-decoration: none;
`;

const SMovieTitle = styled.h1`
	padding: 5px 5px 0 5px;

	text-align: center;
	font-size: 24px;
	font-weight: bold;
	color: var(--textcolor);

	font-size: 20px;
	font-weight: normal;
	text-align: center;
	color: var(--textcolor);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.42;

`;

const SFacts = styled.div`
	display: flex;
	justify-content: center;
	color: var(--textcolor);
	margin: 0 10px 0 10px;
    min-height: 25px;
`;

const SFactsElement = styled.div`
	padding: 0 8px;
	font-size: 14px;
`;

const SNoWrap = styled(SFactsElement)`
	white-space: nowrap;
`;

const SGenre = styled(SFactsElement)`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const SKinoklubb = styled(Kinoklubb)`
	text-align: center;
		padding: 0 0 7px 0;

		@media ${theme.mq.desktop} {
			//@media (orientation: landscape) {
			font-size: 16px;
		}
`;


const SLinks = styled.div`
	margin: 7px 20px 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SReadMoreLink = styled(FilmwebLink)`
	color: var(--textcolor);
	font-size: 14px;
	padding-left: 14px;
`;

const SProgram = styled.div`
	margin: 0 20px;
	overflow: hidden;
    display: flex;
    gap: 5px;
    flex-direction: column;
	>a:last-of-type {
		margin-bottom: 1px;
	}
`;

type SExpanderProps = {
	$isExpanded: boolean;
}

const SExpander = styled.div<SExpanderProps>`
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.8s;
    display: flex;
    gap: 5px;
    flex-direction: column;

	${props => props.$isExpanded ? css`
		max-height: 100vh;
	`: ""}

`;

//#endregion