"use client"
import DateSelectorButton from "src/components/dateselector/DateSelectorButton";
import FilterSelectorButton from "src/components/filterselector/FilterSelectorButton";
import LocationSelectorButton from "src/components/locationselector/LocationSelectorButton";
import theme from "src/components/themes/DefaultTheme";
import { format, isToday } from "date-fns";
import locale from "date-fns/locale/nb";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import styled, { css } from 'src/lib/styles/css';
import ProgramControlsSponsor from "src/components/takoversponsor/ProgramControlsSponsor";
import classNames from "classnames";
import { SanityReference } from "next-sanity";
import useElementInView from "src/lib/client/useElementInView";

//#region [Props]
type ProgramControlsProps = {
    hasSponsor?: boolean;
    sponsorClickUrl?: string | null;
    sponsorTopImageDesktop?: SanityReference[] | null;
    sponsorTopImageMobile?: SanityReference[] | null;
    hasSponsorBg?: boolean;
};
//#endregion

//#region [Component]
export default function ProgramControls({ hasSponsor, sponsorClickUrl, sponsorTopImageDesktop, sponsorTopImageMobile,hasSponsorBg }: ProgramControlsProps) {
    const locationContext = useLocationContext();
    const selectedDateContext = useProgramDatesContext();
    // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
    const { ref, entry } = useElementInView<HTMLDivElement>({
        /* Optional options */
        threshold: 1,
        delay: 200
    });

    let headerText = "Kinoprogram";
    if (locationContext.location) {
        headerText += ` for ${locationContext.location[0].toUpperCase()}${locationContext.location.substring(1)}`;
    }

    if (selectedDateContext.selectedDate) {
        if (isToday(selectedDateContext.selectedDate)) {
            headerText += " i dag";
        } else {
            headerText += ` ${format(selectedDateContext.selectedDate, "iiii d. MMM", { locale })}`;
        }
    }

    const isSticky = (entry?.intersectionRatio ?? 1) < 1;

    return <SControlsContainer className={classNames((entry?.intersectionRatio ?? 1) < 1 ? "isSticky" : undefined, hasSponsorBg ? "hasSponsorBg": undefined)} ref={ref}>
        {hasSponsor && <SProgramControlsSponsor isSticky={isSticky} className={isSticky ? "isSticky" : undefined} sponsorClickUrl={sponsorClickUrl} sponsorTopImageDesktop={sponsorTopImageDesktop} sponsorTopImageMobile={sponsorTopImageMobile}/>}
        <STitle className={classNames(isSticky ? "isSticky" : undefined, hasSponsor ? "hasSponsor" : undefined)}  >{headerText}</STitle>
        <SDateSelectorButton useStaticText />
        <SLocationSelectorButton useStaticText />
        <SFilterSelectorButton />
    </SControlsContainer>;
}
//#endregion


//#region [Styles]
const SControlsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 1px 9px 0 9px;

	justify-content: space-between;
	position: sticky;

	top: -1px;
	z-index: 10;

	margin-bottom: 25px;
    background: var(--backgroundcolor);
    &.hasSponsorBg {
        //background: transparent;
    }

	@media ${theme.mq.desktop} {
		//@media  (orientation: landscape) {
		justify-content: flex-start;
		top: -1px;
		flex-wrap: nowrap;
		gap: 34px;
	}

    &.isSticky {
        padding-bottom: 9px;
		margin-bottom: 0;
        background: var(--backgroundcolor);

        @media ${theme.mq.desktop} {
			//@media  (orientation: landscape) {
			padding-bottom: 0;

		}
    }

`;



const SProgramControlsSponsor = styled(ProgramControlsSponsor)`
    order: 0;
    flex: 0 0 100%;

    &.isSticky {
        order: 1;
        flex: 0 0 100%;
        margin-bottom: 5px;
    }

    @media ${theme.mq.desktop} {
        order: 7 !important;
        margin-left: auto;
    }
`;


const STitle = styled.h1`
	text-align: left;
	padding: 0;
	flex: 0 0 100%;
    order: 1;

	font-size: 24px;
	font-weight: bold;
	color: var(--textcolor);

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		flex: 0 0 auto;
	}

    &.isSticky {
        @media ${theme.mq.desktop} {
			margin-bottom: 0;
		}
        @media ${theme.mq.mobile} {
            font-size: 16px;
            //opacity: 0;
            order: -1;
            margin-bottom: 0;
            flex: 1 1 calc(100% - 60px);


        }
    }
`;

const SDateSelectorButton = styled(DateSelectorButton)`
    order: 3;
`;

const SLocationSelectorButton = styled(LocationSelectorButton)`
    order: 4;
`;

const SFilterSelectorButton = styled(FilterSelectorButton)`
    order: 6;
`;

//#endregion